<template>
	<div v-if="loaded" class="opacity-page">
		<div class="settings-content">
			<div class="list-crud row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="list">
						<div class="item" v-for="bank in banks" @click="viewItem(bank)" v-bind:class="{active: bankSelected.id == bank.id}">
							<div class="name">{{ bank.name }}</div>
							<div class="actions">
								<button @click.stop="editItem(bank)"><icon-edit /></button>
								<button v-if="bank.is_deletable" @click.stop="showModal('delete', {type: 'bank', from: 'nomenclature_bank', model: bank})"><icon-trash /></button>
                                <!-- <button @click.stop="showModal('delete', {type: 'bank', from: 'nomenclature_bank', model: bank})"><icon-trash /></button> -->
							</div>
						</div>
						<div class="item-empty" v-if="!banks.length">
							{{ $t('nomenclature.bank.no-result') }}
						</div>
					</div>
					<div class="crud" id="list-items">
						<div class="box" v-if="viewCrud == 'create'">
							<div class="header">
								<div class="icon-header"><icon-bank /></div>
								<div class="name">{{ $t('nomenclature.bank.create') }}</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('general.name') }}*</label>
                                        <div v-if="$v.bank.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.bank.name.$error}">
										<div class="icon-right" v-if="bank.name != ''" @click.stop="bank.name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('skills.name-ph')" class="input-text no-icon" v-model="bank.name">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.bank.short_name') }}*</label>
                                        <div v-if="$v.bank.short_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.bank.short_name.$error}">
										<div class="icon-right" v-if="bank.short_name != ''" @click.stop="bank.short_name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('nomenclature.bank.short-name-ph')" class="input-text no-icon" v-model="bank.short_name">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.bank.company_iban') }}</label>
										<div v-if="!$v.bank.company_iban.minLength" class="error-msg">{{ $t('validator.required_iban')}}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.bank.company_iban.$error}">
										<div class="icon-right" v-if="bank.company_iban != ''" @click.stop="bank.company_iban = ''"><icon-close class="icon-clear" /></div>
										<input :maxlength="24" type="text" :placeholder="$t('nomenclature.bank.company-iban-ph')" onkeydown="if(event.key==='.' || event.key==='-'){event.preventDefault();}" class="input-text no-icon" v-model="bank.company_iban">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.bank.code') }}*</label>
                                        <div v-if="$v.bank.code.$error && $v.bank.code.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
										<div v-if="!$v.bank.code.minLength" class="error-msg">{{ $t('validator.required_code') }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.bank.code.$error}">
										<div class="icon-right" v-if="bank.code != ''" @click.stop="bank.code = ''"><icon-close class="icon-clear" /></div>
										<input :maxlength="4" type="text" :placeholder="$t('nomenclature.bank.code-ph')" onkeydown="if(event.key==='.' || event.key==='-'){event.preventDefault();}" class="input-text no-icon" v-model="bank.code">
									</div>
								</div>
                                <div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.bank.is_active') }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="bank.is_active = 0" v-bind:class="{active: bank.is_active === 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="bank.is_active = 1" v-bind:class="{active: bank.is_active === 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group profile">
                                    <div class="upload-img update bank">
                                        <div class="text">
                                            <p class="title">{{ $t('nomenclature.bank.logo') }}</p>
                                            <div onclick="document.getElementById('avatarFile').click();"  class="with-image"><icon-upload /><p>{{ $t('profile.upload_new_picture') }}</p></div>
                                        </div>
                                        <input style="display:none" id="avatarFile" type="file" accept="image/png, image/jpg, image/jpeg, image/svg" v-on:change="onAvatarChange">
                                        <img v-if="urlAvatar" :src="urlAvatar" class="img-rectangular"/>
                                    </div>
                                </div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.add') }}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'edit'">
							<div class="header">
								<div class="icon-header"><icon-bank /></div>
								<div class="name">{{ bankSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf white only-icon" @click="resetToList('item')">
										<div class="icon"><icon-close /></div>
									</button>
								</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('general.name') }}*</label>
                                        <div v-if="$v.bank.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.bank.name.$error}">
										<div class="icon-right" v-if="bank.name != ''" @click.stop="bank.name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('skills.name-ph')" class="input-text no-icon" v-model="bank.name">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.bank.short_name') }}*</label>
                                        <div v-if="$v.bank.short_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.bank.short_name.$error}">
										<div class="icon-right" v-if="bank.short_name != ''" @click.stop="bank.short_name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('nomenclature.bank.short-name-ph')" class="input-text no-icon" v-model="bank.short_name">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.bank.company_iban') }}</label>
                                        <div v-if="!$v.bank.company_iban.minLength" class="error-msg">{{ $t('validator.required_iban')}}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.bank.company_iban.$error}">
										<div class="icon-right" v-if="bank.company_iban != ''" @click.stop="bank.company_iban = ''"><icon-close class="icon-clear" /></div>
										<input :maxlength="24" type="text" :placeholder="$t('nomenclature.bank.company-iban-ph')" onkeydown="if(event.key==='.' || event.key==='-'){event.preventDefault();}" class="input-text no-icon" v-model="bank.company_iban">
									</div>
								</div>
                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.bank.code') }}*</label>
                                        <div v-if="$v.bank.code.$error && $v.bank.code.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
										<div v-if="!$v.bank.code.minLength" class="error-msg">{{ $t('validator.required_code') }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.bank.code.$error}">
										<div class="icon-right" v-if="bank.code != ''" @click.stop="bank.code = ''"><icon-close class="icon-clear" /></div>
										<input :maxlength="4" type="text" :placeholder="$t('nomenclature.bank.code-ph')" onkeydown="if(event.key==='.' || event.key==='-'){event.preventDefault();}" class="input-text no-icon" v-model="bank.code">
									</div>
								</div>
                                <div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.bank.is_active') }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="bank.is_active = 0" v-bind:class="{active: bank.is_active === 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="bank.is_active = 1" v-bind:class="{active: bank.is_active === 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group profile">
                                    <div class="upload-img update bank">
                                        <div class="text">
                                            <p class="title">{{ $t('nomenclature.bank.logo') }}</p>
                                            <div onclick="document.getElementById('avatarFile').click();" class="with-image"><icon-upload /><p>{{ $t('profile.upload_new_picture') }}</p></div>
                                        </div>
                                        <input style="display:none" id="avatarFile" type="file" accept="image/png, image/gif, image/jpg, image/jpeg, image/svg" v-on:change="onAvatarChange">
                                        <img v-if="urlAvatar" :src="urlAvatar" class="img-rectangular"/>
                                    </div>
                                </div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.update') }}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'view'">
							<div class="header">
								<div class="icon-header"><icon-bank /></div>
								<div class="name">{{ bankSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf blue only-icon" @click="editItem(bankSelected)">
										<div class="icon"><icon-edit /></div>
									</button>
									<button class="btn-tbf white only-icon" @click="resetToList('item')">
										<div class="icon"><icon-close /></div>
									</button>
								</div>
							</div>
							<div class="data-item">
								<div class="label">{{ $t('nomenclature.bank.short_name') }}</div>
								<div class="text">{{ bankSelected.short_name ? bankSelected.short_name : '-'}}</div>
                                <div class="label">{{ $t('nomenclature.bank.company_iban') }}</div>
								<div class="text">{{ bankSelected.company_iban ? bankSelected.company_iban : '-'}}</div>
                                <div class="label">{{ $t('nomenclature.bank.code') }}</div>
								<div class="text">{{ bankSelected.code ? bankSelected.code : '-'}}</div>
                                <div v-if="urlAvatar" class="label">{{ $t('nomenclature.bank.logo') }}</div>
								<div v-if="urlAvatar" class="input-group profile">
                                    <div class="upload-img update bank">
                                        <img v-if="urlAvatar" :src="urlAvatar" class="img-rectangular"/>
                                    </div>
                                </div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
	<loader-settings-page v-else/>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconBank from '../../Icons/Bank'
    import IconCalculator from '../../Icons/Calculator'
    import iconUpload from '../../Icons/Upload'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'
	import { required, requiredIf, minLength, maxLength } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
			IconBank,
            IconCalculator,
            iconUpload,
			LoaderSettingsPage
		},
		data() {
			return {
				loaded: false,
				viewCrud: 'create',
				banks: [],
                urlAvatar: '',
                bank:{
                    name: '',
                    short_name: '',
                    company_iban: '',
                    code: '',
                    is_active: 1,
                    file: ''
                },
				bankSelected: '',
				error_message: '',
                is_deletable: 0
			}
		},
		async mounted(){
			await this.getBanksList()

			setTimeout(() => {
				var title = this.$t('nomenclature-sidebar.banks');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshBanksNomenclature', () => {
				this.viewCrud = 'create';
				this.getBanksList()
			})
		},
		validations: {
            bank:{
                name: { required },
                short_name: { required },
                company_iban: {
                    required: requiredIf(prop => prop.company_iban !== ''),
                    minLength: minLength(24),
                    maxLength: maxLength(24)  
                },
                code: { 
                    required, 
                    minLength: minLength(4),
                    maxLength: maxLength(4)  
                }
            },
		},
		methods: {
			async getBanksList(){
				await axios.get(this.$auth.user().instance.id + '/banks')
				.then(({data}) => {
					this.banks = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName)
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
                    let objData = new FormData();

                    if(this.bank.file) { objData.append('file', this.bank.file) }
					objData.append('name', this.bank.name.charAt(0).toUpperCase() + this.bank.name.slice(1),)
					objData.append('short_name', this.bank.short_name.charAt(0).toUpperCase() + this.bank.short_name.slice(1))
					objData.append('company_iban', this.bank.company_iban)
					objData.append('code', this.bank.code)
					objData.append('is_active', this.bank.is_active)

					if(type == 'create'){
						this.addBank(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateBank(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			addBank(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/banks/store', objData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getBanksList()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.bankSelected = data.data;
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
                            this.error_message = Object.values(error.response.data.errors).join('')
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			editItem(bank){
				this.scrollTop()
				this.viewCrud = 'edit'
				this.bankSelected = bank
				this.bank.name = bank.name
                this.bank.short_name = bank.short_name
                this.bank.value = bank.value
                this.bank.code = bank.code
                this.bank.is_active = bank.is_active ? bank.is_active : 0
                this.urlAvatar = bank.file_path ? bank.file_path : ''
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			updateBank(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
                objData.append('_method', 'PATCH')
				axios.post(`/banks/${this.bankSelected.id}`, objData,{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getBanksList()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.bankSelected = this.banks.find(el => el.id == this.bankSelected.id)
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
                            this.error_message = Object.values(error.response.data.errors).join('')
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			resetToList(from){
				this.viewCrud = 'create'
				if(from == 'item') {
					this.bankSelected = ''
					this.urlAvatar = ''
				}
				this.bank.name = ''
                this.bank.short_name = ''
                this.bank.company_iban = ''
                this.bank.code = ''
                this.bank.is_active = 0
                this.bank.file = ''
			},
			viewItem(item){
				this.scrollTop()
				this.viewCrud = 'view'
				this.bankSelected = item
                this.urlAvatar = item.file_path ? item.file_path : ''
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			},
            onAvatarChange(e){
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.urlAvatar = URL.createObjectURL(files[0]);
				this.bank.file = files[0]
			}
		}
	};
</script>